<template>
  <div class="main">
    <!--list.length<5-->
    <heads :msg="checkedAddressId? '选择地址':'地址管理'" :isShowRightMenu = 'false' :states="1" :address_management1="1"></heads>
    <div class="div_address_item" v-for="(item,index) in list" v-show="isShow" :key="index" @click="selectAddress(item)">
      <div class="div_item_content">
        <p v-if="checkedAddressId" class="p_icon" :class="checkedAddressId == item.id?'icon_select':'icon_defult'">{{ get_sliceOne(item.receiver) }}</p>
        <p v-else class="p_icon" :class="item.is_default?'icon_select':'icon_defult'">{{ get_sliceOne(item.receiver) }}</p>
        <div class="div_item_content_msg">
          <div>
            <p class="p_name">{{ item.receiver+"  "+item.mobile}}</p>
            <p class="p_address">{{ `${item.province} ${item.city} ${item.county} ${item.detail_address}` }}</p>
          </div>
          <van-icon v-show="checkedAddressId " size="20" name="checked" :color="checkedAddressId == item.id?'#ee2e2e':'#eeeeee'"/>
        </div>
      </div>
      <div class="div_item_bottom">
        <div class="div_item_bottom_set_default" @click.stop="defaults(item.id)">
          <van-icon size="20" name="checked" :color="item.is_default?'#ee2e2e':'#eeeeee'"/>
          <span :class="item.is_default ? 'sp_checkbox_checked':'sp_checkbox'">{{item.is_default?'已设置默认':'默认'}}</span>
        </div>
         <div class="div_item_bottom_option" >
           <span  @click.stop="edit_address(item)">编辑</span>
           <span  @click.stop="del(item.id)">删除</span>
         </div>
      </div>
    </div>
    <div class="div_empty"></div>
    <van-button round type="danger" class="btn_submit" block @click="go_add_addressJd()">+ 添加收货地址</van-button>
    <div v-show="!isShow" class="kongzt">
      <img src="../../static/images/my_index/kongzt.png" alt>
      <p>您还没有添加地址哦~</p>
    </div>
    <!--<div v-for="(item,index) in list" v-show="isShow" :key="index" class="main" @click="selectAddress(item)">
      <div class="left_box">
        <p :class="item.is_default?'icon_select':'icon_defult'">{{ get_sliceOne(item.receiver) }}</p>
      </div>
      <div class="right_box">
        <div class="right_top">
          <p class="title"></p>
          <p class="tel">{{  }}</p>
          <p class="btns">
            <span v-if="item.is_default === 1 || item.is_default === '1'" class="btn_select" @click.stop="defaults(item.id)">默认地址</span>
            <span v-else class="btn_defult" >设为默认</span>
          </p>
        </div>
        <div class="right_bottom">
          <div class="bottom_left">
            <p>{{ `${item.province} ${item.city} ${item.county} ${item.detail_address}` }}</p>
          </div>
          <div class="bottom_right">
            <span @click.stop="edit_address(item)">编辑</span>
            <span @click.stop="del(item.id)">删除</span>
          </div>
        </div>
      </div>
    </div>-->

  </div>
</template>

<script>
  import heads from '@/compontens/heads.vue'
  import {get_list, set_default_address} from '@/api/my_user.js'

  export default {
  components: {
    heads
  },
  data () {
    return {
      isItemCanClick:this.$route.query.isItemCanClick,
      checkedAddressId:this.$route.query.edi_address_id,
      order_id:this.$route.query.order_id,
      backs: 0,
      list: [],
      a: '',
      b: 'asasass',
      isShow: true
    }
  },
  watch:{
    $route: {
      handler(to,from) {
        if (sessionStorage.getItem('edi_address_id')){
          this.checkedAddressId =  sessionStorage.getItem('edi_address_id')
        }else {
          this.checkedAddressId=this.$route.query.edi_address_id
        }
        this.getlist()
      }
    }
  },
  mounted () {
    if (sessionStorage.getItem('edi_address_id')){
      this.checkedAddressId =  sessionStorage.getItem('edi_address_id')
    }else {
      this.checkedAddressId=this.$route.query.edi_address_id
    }
    this.getlist()
  },
  methods: {
    go_add_addressJd() {
      if (this.list.length>=5){
        this.$toast("最多添加五个收货地址")
      }else {
        this.$router.push({ path: '/index/express/add_address' })
      }
    },
    // 选中地址
    checkedAddredd (item) {
      this.$router.replace({
        path: '/index/express/edit_address',
        query: {
          one_id: item.id,
          order_id: this.order_id,
          backs: this.$route.query.backs ? this.$route.query.backs : ''
        }
      })
    },
    // 截取姓名第一位
    get_sliceOne (data) {
      var new_data = data.slice(0, 1)
      return new_data
    },
    // 获取地址列表
    getlist () {
      get_list().then(res => {
        if (!res.result.list.length) {
          this.isShow = false
        }
        this.list = res.result.list
      })
    },
    // 设为默认地址
    defaults (ids) {
      const params = {
        id: ids,
        is_default: 1
      }
      set_default_address(params).then(res => {
        this.getlist()
      })
    },
    // 删除地址
    del (ids) {
      const params = {
        id: ids,
        status: 0
      }
      set_default_address(params).then(res => {
        this.getlist()
      })
    },
    // 编辑
    edit_address (item) {
      sessionStorage.setItem('address_info', JSON.stringify(item))
      this.$router.push({
        path: '/index/express/edit_address',
        query: {
          order_id: this.order_id,
          one_id: item.id,
          is_edi:true,
          backs: this.$route.query.backs ? this.$route.query.backs : ''
        }
      })
    },
    // 选地址
    selectAddress (item) {
      if (this.checkedAddressId||this.isItemCanClick){
        this.checkedAddredd(item)
      } else if (this.$route.query.type == 'exchangeCode') {
        this.$router.push({
          path: '/ExchangeCode',
          query: {
            AddressInfo: JSON.stringify(item),
            codeadd: this.$route.query.code,
            pct_name: this.$route.query.pct_name,
            thumb: this.$route.query.thumb
          }
        })
        return false
      } else if (this.$route.query.select === '1') {
        sessionStorage.setItem('selectAdd', JSON.stringify(item))
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn_submit{
  width: 80%;
  background: #ff272c;
  position: fixed;
  left: 50%;
  margin-left: -40%;
  margin-bottom: 40px;
  bottom:0;
}
.div_empty{
  height: 200px;
  background: #EEEEEE;
}
 .main {
    height: 100vh;
    background: #EEEEEE;
    width: 100%;
 }
.div_address_item{
  height: 244px;
  margin-bottom: 20px;
  background: white;
  padding: 0px 30px 30px 30px;
  .div_item_content{
    display: flex;
    justify-content: flex-start;
    height: 186px;
    border-bottom: 1px solid #DEDEDE;
    .div_item_content_msg{
      height: 186px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .p_name{
        font-size: 30px;
        line-height: 48px;
        color: #333333;
        font-weight: 700;
      }
      .p_address{
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 8px;
        font-size: 24px;
        line-height: 32px;
        color: #333333;
        letter-spacing: 0;
      }
    }
    .p_icon{
      flex-shrink:0;
      width: 64px;
      height: 64px;
      margin-top: 62px;
      line-height: 64px;
      margin-right: 24px;
      color: #ffffff;
      border-radius: 50%;
      text-align: center;
    }
    .icon_select {
      background-image: linear-gradient(136deg, #FF5252 0%, #EE2E2E 100%);
    }

    .icon_defult {
      background: #DEDEDE;
    }



  }
  .div_item_bottom{
    margin-top: 8px;
    height: 50px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    span{
      color: #666666;
    }
    .div_item_bottom_option{
      span {
        font-size: 28px;
        color: #666666;
        margin-left: 20px;
      }
    }
    .div_item_bottom_set_default{
      display: flex;
      align-items: center;
      .sp_checkbox{
        display: inline-block;
        color: #666666;
      }
      .sp_checkbox_checked{
        display: inline-block;
        color: #ee2e2e;
      }
      span {
        font-size: 28px;
        margin-left: 16px;
      }
    }
  }

}
.left_box {
  padding: 0 30px 0 20px;
}
.left_box .icon_select {
  background-color: #ee2e2e;
  text-align: center;
  width: 64px;
  height: 64px;
  line-height: 64px;
  color: #ffffff;
  border-radius: 50%;
  margin-top: 53px;
}
.left_box .icon_defult {
  background-color: #b8b8b8;
  text-align: center;
  width: 64px;
  height: 64px;
  line-height: 64px;
  color: #ffffff;
  border-radius: 50%;
  margin-top: 53px;
}

.right_box {
  width: calc(100% - 114px);
}
.right_top {
  height: 50%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.title {
  padding: 0 10px 0 0;
  font-size: 30px;
  color: #333;
}
.tel {
  background-color: white;
  color: #999;
  font-size: 30px;
  margin-left: 10px;
}
.btns {
  display: flex;
  align-items: center;
  width: 200px;
}
.btn_defult {
  color: #ee2e2e;
  border: 2px solid #ee2e2e;
  border-radius: 30px;
  margin-left: 20px;
  display: inline-block;
  font-size: 24px;
  width: 125px;
  text-align: center;
}
.btn_select {
  background: #ee2e2e;
  color: #ffffff;
  border: 2px solid #ee2e2e;
  border-radius: 30px;
  margin-left: 20px;
  display: inline-block;
  font-size: 24px;
  width: 125px;
  text-align: center;
}

.right_bottom {
  height: calc(100% - 80px);
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.bottom_left {
  width: calc(100% - 160px);
  p {
    margin-top: 20px;
    font-size: 25px;
    color: #333;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}
.bottom_right {
  padding: 0 20px 0 20px;
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #999;
  }
}
.kongzt {
  width: 100%;
  img {
    width: 310px;
    height: 310px;
    margin: 0 auto;
    display: block;
    margin-top: 300px;
  }
  p {
    font-size: 30px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding-top: 30px;
  }
}
</style>
